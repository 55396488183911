import React from "react";
import FoundationPage from "../../components/FoundationPage";
import Layout from "../../components/Layout";

export default function LogoPage({ location }) {
  return (
    <Layout location={location}>
      <FoundationPage
        pageTitle="Logo"
        // description=""
        link1title="View in Figma"
        link1url="https://www.figma.com/file/08RyCY34fSWYCxwrFWbojr/GDS-%2F-Core-Library?node-id=19910%3A516780"
        link3title="View Web Components Storybook"
        link3url="https://nielsen-media.gitlab.io/ma/design-libraries/gds-web-components/development/storybook/?path=/story/foundations-logos--logos"
      >
        <React.Fragment>
          <div className="section">
            <p className="description mt-space-600">
              In 2021, Nielsen launched a redesign of our visual brand identity.
              The new brand was a collaboration between branding agency
              Siegel+Gale, Nielsen's marketing team, and even the GDS team
              pitched in. To support the new brand, the marketing team created
              various resources to dictate proper usage.
            </p>
            <p className="description mt-space-400">
              The Nielsen logo is just one part of that branding work. Please
              review their work and guidelines whenever you have any questions
              about our visual branding.
            </p>
            <div className="d-flex my-space-700 gap-space-500 align-items-center">
              <gds-logo icon-only="true"></gds-logo>
              <gds-logo></gds-logo>
              <gds-logo logo="gracenote"></gds-logo>
            </div>
          </div>
        </React.Fragment>
      </FoundationPage>
    </Layout>
  );
}
